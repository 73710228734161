html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    font-weight: 200;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1 {
  font-size: 2.8rem;
  font-weight: 200;
}

h2 {
  font-size: 2.8rem;
  font-weight: 600;
}

h3 {
  font-size: 2.2rem;
  font-weight: 400;

  @media (max-width: 768px) {
    font-size: 1.5rem;
   };

   @media (max-width: 400px) {
    font-size: 1rem;
   };
}


p {
  font-size: 1.2rem;
  line-height: 1.8rem;
}

table {
  font-size: 1.2rem;
  line-height: 1.8rem;
}

li {
  font-size: 1.2rem;
  line-height: 1.8rem;
}